<template>
  <div>
    <v-row>
      <v-col cols="grow">
        <v-text-field
          v-model="search_filter.key"
          @change="filterChanged"
          @keyup.enter="sumbitFilter"
          :label="!exportation ? 'Search' : 'Name'"
          hide-details
          clearable
          color="secondary"
          outlined
          dense
        >
          <template v-if="!exportation" class="d-flex align-center" v-slot:append>
            <v-btn @click="sumbitFilter" icon color="primary">
              <v-icon>$search</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row v-show="show_filter || exportation">
        <v-col cols="2" :md="!exportation ? 2 : 6" sm="12" xs="12" class="pt-0">
          <v-select
            v-model="search_filter.sort"
            @change="filterChanged"
            :items="filter.sort_items"
            label="All Prices"
            clearable
            color="secondary"
            hide-details
            outlined
            dense
          >
          </v-select>
        </v-col>
        <v-col cols="2" :md="!exportation ? 2 : 6" sm="12" xs="12" class="pt-0">
          <v-autocomplete
            @change="filterChanged"
            v-model="search_filter.brands"
            :items="filter.brands_items"
            label="Brands"
            color="secondary"
            multiple
            outlined
            hide-details
            clearable
            dense
          >
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-3">
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span>{{ item.brand.toUpperCase() }}</span>
                      <v-spacer></v-spacer>
                      <v-chip v-if="item.count > 0" text-color="white" color="primary" small>{{
                        item.count
                      }}</v-chip>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" style="height: auto" v-if="index === 0">
                <span>{{ item.brand.toUpperCase() }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ search_filter.brands.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" :md="!exportation ? 2 : 6" sm="12" xs="12" class="pt-0">
          <v-autocomplete
            v-model="search_filter.categories"
            :items="filter.categories_items"
            label="Categories"
            color="secondary"
            multiple
            outlined
            hide-details
            clearable
            dense
          >
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-3">
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <span>{{ item.category.toUpperCase() }}</span>
                      <v-spacer></v-spacer>
                      <v-chip v-if="item.count > 0" text-color="white" color="primary" small>{{
                        item.count
                      }}</v-chip>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-chip color="primary" style="height: auto" v-if="index === 0">
                <span>{{ item.category.toUpperCase() }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text caption">
                (+{{ search_filter.categories.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2" :md="!exportation ? 2 : 6" sm="12" xs="12" class="pt-0">
          <v-autocomplete
            v-model="search_filter.availabilities"
            @change="filterChanged"
            :items="filter.availability_items"
            label="My availabilities"
            color="secondary"
            multiple
            outlined
            hide-details
            clearable
            dense
          >
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-3">
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <badge
                        small="true"
                        :text="item.availability"
                        :color="
                          item.availability == 'InStock'
                            ? 'success'
                            : item.availability == 'NotFound'
                              ? 'grey'
                              : 'error'
                        "
                      />
                      <v-spacer></v-spacer>
                      <v-chip v-if="item.count > 0" text-color="white" color="primary" small>{{
                        item.count
                      }}</v-chip>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, index }">
              <badge
                v-if="index === 0"
                small="true"
                :text="item.availability"
                :color="
                  item.availability == 'InStock'
                    ? 'success'
                    : item.availability == 'NotFound'
                      ? 'grey'
                      : 'error'
                "
              />
              <span v-if="index === 1" class="grey--text caption">
                (+{{ search_filter.availabilities.length - 1 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="2"
          :md="!exportation ? 2 : 6"
          sm="12"
          xs="12"
          class="pt-0"
          :hidden="!show_more_filters"
        >
          <v-autocomplete
            v-model="search_filter.competitors_availabilities"
            @change="filterChanged"
            :items="filter.competitors_availabilities_items"
            label="Competitors availabilities"
            color="secondary"
            multiple
            outlined
            hide-details
            clearable
            dense
          >
            <template v-slot:item="{ active, item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action class="mr-3">
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      <badge
                        small="true"
                        :text="item.availability"
                        :color="
                          item.availability == 'InStock'
                            ? 'success'
                            : item.availability == 'NotFound'
                              ? 'grey'
                              : 'error'
                        "
                      />
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <template v-slot:selection="{ item, index }">
              <badge
                v-if="index === 0"
                small="true"
                :text="item.availability"
                :color="
                  item.availability == 'InStock'
                    ? 'success'
                    : item.availability == 'NotFound'
                      ? 'grey'
                      : 'error'
                "
              />
              <span v-if="index === 1" class="grey--text caption">
                (+{{ search_filter.competitors_availabilities.length - 1 }}
                others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" :md="!exportation ? 4 : 6" sm="12" xs="12" class="pt-1">
          <v-row>
            <v-col cols="12">
              <v-menu
                v-if="show_more_filters"
                open-on-click
                bottom
                :close-on-content-click="false"
                offset-y
                v-model="menu_products"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-chip
                    outlined
                    class="sm-text font-weight-bold text--secondary mr-1"
                    v-on="{ ...menu }"
                  >
                    <v-icon icon="start" small class="mr-2">mdi-plus-circle-outline</v-icon>
                    <span class="mr-1">Products</span>
                    <span v-if="search_filter.matched"> | 1</span>
                  </v-chip>
                </template>
                <v-card flat class="pa-0">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pa-0 pb-2 text-right">
                        <v-icon icon="start" small class="mr-2" @click="menu_products = false"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-select
                        v-model="search_filter.matched"
                        @change="filterChanged"
                        :items="filter.matched_items"
                        label="Products"
                        clearable
                        color="secondary"
                        hide-details
                        outlined
                        dense
                      >
                      </v-select>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                v-if="show_more_filters"
                open-on-click
                bottom
                :close-on-content-click="false"
                offset-y
                v-model="menu_source"
              >
                <template v-slot:activator="{ on: menu }">
                  <v-chip
                    outlined
                    class="sm-text font-weight-bold text--secondary mr-1"
                    v-on="{ ...menu }"
                  >
                    <v-icon icon="start" small class="mr-2">mdi-plus-circle-outline</v-icon>
                    <span class="mr-1">Source</span>
                    <span v-if="search_filter.source"> | 1</span>
                  </v-chip>
                </template>
                <v-card flat class="pa-0">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pa-0 pb-2 text-right">
                        <v-icon icon="start" small class="mr-2" @click="menu_source = false"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-select
                        v-model="search_filter.source"
                        @change="filterChanged"
                        :items="filter.source_items"
                        label="Source"
                        clearable
                        color="secondary"
                        hide-details
                        outlined
                        dense
                      >
                      </v-select>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                open-on-click
                bottom
                :close-on-content-click="false"
                v-model="menu_competitors"
                offset-y
              >
                <template v-slot:activator="{ on: menu }">
                  <v-chip
                    outlined
                    class="sm-text font-weight-bold text--secondary mr-1"
                    v-on="{ ...menu }"
                  >
                    <v-icon icon="start" small class="mr-2">mdi-plus-circle-outline</v-icon>
                    <span class="mr-1">Competitors</span>
                    <span v-if="search_filter.competitors_websites.length">
                      | {{ search_filter.competitors_websites.length }}</span
                    >
                  </v-chip>
                </template>
                <v-card flat class="pa-0">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pa-0 pb-2 text-right">
                        <v-icon icon="start" small class="mr-2" @click="menu_competitors = false"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-autocomplete
                        v-model="search_filter.competitors_websites"
                        @change="filterChanged"
                        :items="filter.competitors_websites_items"
                        label="Competitors"
                        color="secondary"
                        multiple
                        outlined
                        hide-details
                        clearable
                        dense
                      >
                        <template v-slot:item="{ active, item, attrs, on }">
                          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-action class="mr-3">
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <div class="d-flex align-center">
                                    <img
                                      :src="
                                        'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
                                        item.competitor_website
                                      "
                                      @error="replaceByDefault"
                                      width="30"
                                      height="30"
                                      class="mr-2 favicon-img"
                                    />{{ item.competitor_website }}
                                  </div>
                                  <v-spacer></v-spacer>
                                  <v-chip
                                    v-if="item.count > 0"
                                    text-color="white"
                                    color="primary"
                                    small
                                    >{{ item.count }}</v-chip
                                  >
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>

                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" style="height: auto" v-if="index === 0">
                            <span>{{ item.competitor_website }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ search_filter.competitors_websites.length - 1 }} others)
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                open-on-click
                bottom
                :close-on-content-click="false"
                v-model="menu_price_index"
                offset-y
              >
                <template v-slot:activator="{ on: menu }">
                  <v-chip
                    outlined
                    class="sm-text font-weight-bold text--secondary mr-1"
                    v-on="{ ...menu }"
                  >
                    <v-icon icon="start" small class="mr-2">mdi-plus-circle-outline</v-icon>
                    <span class="mr-1"> Price index </span>
                    <span v-if="search_filter.price_index.max || search_filter.price_index.min"
                      >| {{ 1 }}</span
                    >
                  </v-chip>
                </template>
                <v-card flat class="pa-0">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="pa-0 pb-2 text-right">
                        <v-icon icon="start" small class="mr-2" @click="menu_price_index = false"
                          >mdi-close-circle-outline</v-icon
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pa-0">
                      <v-col cols="6" class="d-flex justify-center pa-0 pr-1">
                        <v-text-field
                          label="Min"
                          style="width: 100px"
                          :max="0"
                          type="number"
                          v-model="search_filter.price_index.min"
                          dense
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-center pa-0 pl-1">
                        <v-text-field
                          label="Max"
                          style="width: 100px"
                          type="number"
                          :min="0"
                          v-model="search_filter.price_index.max"
                          dense
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-chip
                :class="show_more_filters ? 'mt-1' : 'mt-0'"
                outlined
                @click="showMoreFilter()"
                class="sm-text font-weight-bold text--secondary"
              >
                <v-icon icon="start" small class="mr-2">{{
                  show_more_filters ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'
                }}</v-icon>
                {{ show_more_filters ? 'Less filters' : 'More filters' }}
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          v-if="exportation"
          cols="2"
          :md="!exportation ? 2 : 6"
          sm="12"
          xs="12"
          class="d-flex align-center pt-0"
        >
          <v-row>
            <v-col cols="6">
              <v-btn large block light color="primary" @click="sumbitFilter"
                >Preview
                <v-icon right dark>$filter</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn large light text block color="primary" @click="resetDashboard"
                >Reset
                <v-icon right dark>$reset</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="8">
              <span v-for="item in applied_filters" v-bind:key="item.filter">
                <v-chip
                  class="mr-2"
                  outlined
                  color="primary"
                  small
                  @click="resetFilter(item.filter)"
                >
                  <v-icon icon="start" small class="mr-2">mdi-close-circle-outline</v-icon>
                  {{ item.filter }} | {{ item.value }}</v-chip
                >
              </span>
            </v-col>
            <v-col
              v-if="!exportation"
              cols="4"
              :md="!exportation ? 4 : 6"
              class="d-flex align-right pt-0"
            >
              <v-btn
                light
                text
                color="primary"
                class="btn-min-width mr-2 ml-auto"
                @click="resetDashboard"
                >Reset
                <v-icon right dark>$reset</v-icon>
              </v-btn>
              <v-btn light color="primary" class="btn-min-width" @click="sumbitFilter"
                >Filter
                <v-icon right dark>$filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
    <div v-if="!exportation" class="d-flex align-center justify-center mt-2" @click="showFilters">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="d-flex align-center justify-center cursor-pointer"
            style="
              width: 120px;
              height: 20px;
              background: rgba(0, 0, 0, 0.12);
              border-radius: 2px 2px 8px 8px;
            "
          >
            <span>{{ show_filter ? 'Hide filters' : 'Show filters' }}</span>
            <v-icon v-if="show_filter">$arrow_up</v-icon>
            <v-icon v-else>$arrow_down</v-icon>
          </span>
        </template>
        <span>{{ show_filter ? 'Hide filters' : 'Show filters' }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'filterInputs',
    props: ['component_name', 'exportation', 'opened'],
    data() {
      return {
        menu: false,
        menu_products: false,
        menu_source: false,
        menu_competitors: false,
        menu_price_index: false,
        applied_filters: [],
        filter: {
          sort_items: ['Lowest Prices', 'Highest Prices', 'Equal Prices'],
          matched_items: ['Matched', 'Not Matched', 'Validated', 'Not Validated'],
          source_items: ['Google shopping', 'Direct crawling'],
          competitors_websites_items: [],
          competitors_availability_items: [],
          availability_items: [],
          brands_items: [],
          categories_items: [],
          index: [-100, 100],
          price_index: {
            min: null,
            max: null,
          },
        },
        search_filter: {
          key: '',
          sort: '',
          matched: '',
          source: '',
          competitors_websites: [],
          competitors_availabilities: [],
          availabilities: [],
          brands: [],
          categories: [],
          price_index: {
            min: null,
            max: null,
          },
        },
        show_filter: false,
        show_more_filters: false,
      }
    },
    methods: {
      resetCompetitors() {
        this.search_filter.competitors_websites = []
      },
      resetPriceIndex() {
        this.search_filter.price_index.min = null
        this.search_filter.price_index.max = null
      },
      resetFilter(filter = '') {
        switch (filter) {
          case 'index':
            this.filter.price_index.min = null
            this.filter.price_index.max = null
            this.applied_filters = this.applied_filters.filter((elt) => elt.filter !== 'index')
            break
          default:
            break
        }
      },
      apply(filter = '') {
        switch (filter) {
          case 'index':
            if (this.filter.price_index.min == null || this.filter.price_index.max == null) return
            this.search_filter.price_index.min = this.filter.price_index.min
            this.search_filter.price_index.max = this.filter.price_index.max
            if (this.applied_filters.filter((elt) => elt.filter === 'index').length === 0) {
              this.applied_filters.push({
                filter: 'index',
                value: `${this.filter.price_index.min} - ${this.filter.price_index.max}`,
              })
            } else {
              this.applied_filters = this.applied_filters.map((elt) => {
                if (elt.filter === 'index') {
                  elt.value = `${this.filter.price_index.min} - ${this.filter.price_index.max}`
                }
                return elt
              })
            }

            break
          default:
            break
        }
        this.menu = !this.menu
      },
      showMoreFilter() {
        this.show_more_filters = !this.show_more_filters
      },
      filterChanged() {
        this.$emit('filter-changed', this.search_filter)
      },
      showFilters() {
        this.show_filter = !this.show_filter
        this.clearImputs()
      },
      sumbitFilter() {
        this.$emit('load-data', this.search_filter)
      },
      resetDashboard() {
        this.clearImputs()
        this.$emit('load-data', this.search_filter)
      },
      clearImputs() {
        this.search_filter.availabilities = []
        this.search_filter.competitors_websites = []
        this.search_filter.competitors_availabilities = []
        this.search_filter.brands = []
        this.search_filter.categories = []
        this.search_filter.sort = ''
        this.search_filter.key = ''
        this.search_filter.matched = ''
        this.search_filter.source = ''
        this.search_filter.price_index.min = null
        this.search_filter.price_index.max = null
        this.applied_filters = []
      },
      async getData() {
        await axios
          .get('/api/products/getfilterdata', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
            },
          })
          .then((res) => {
            this.filter.brands_items = res.data.brands
              .map((elt) => {
                elt.text = elt.brand
                return elt
              })
              .filter((elt) => elt.brand.length)

            this.filter.categories_items = res.data.categories
              .map((elt) => {
                elt.text = elt.category
                return elt
              })
              .filter((elt) => elt.category.length)

            this.filter.availability_items = res.data.availabilities
              .map((elt) => {
                elt.text = elt.availability
                return elt
              })
              .filter((elt) => elt.availability.length)
            this.filter.availability_items.push({
              availability: 'NotFound',
              text: 'NotFound',
              count: 0,
            })

            this.filter.competitors_websites_items = res.data.competitors_websites
              .map((elt) => {
                elt.text = elt.competitor_website
                return elt
              })
              .filter((elt) => elt.competitor_website.length)

            //this.search_filter.competitors_websites = this.filter.competitors_websites_items.slice(
            //  0,
            //  1,
            //)

            //console.log('lenght :', this.search_filter.competitors_websites.length)

            this.filter.competitors_availabilities_items = res.data.competitors_availabilities
              .map((elt) => {
                elt.text = elt.availability
                return elt
              })
              .filter((elt) => elt.availability.length)

            //this.search_filter.competitors_availabilities =
            //  this.filter.competitors_availabilities_items

            this.filter.competitors_availabilities_items.push({
              availability: 'NotFound',
              text: 'NotFound',
              count: 0,
            })
          })
      },
    },
    mounted() {
      this.getData()
    },
    watch: {
      opened: {
        handler() {
          this.resetDashboard()
        },
      },
    },
  }
</script>
<style scoped>
  .btn-min-width {
    min-width: 130px !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
</style>
