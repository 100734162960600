export default function singlePriceHandler(price) {
  let country = JSON.parse(localStorage.user_website).country
  let offer_html = ''
  if (price)
    switch (country) {
      case 'mx':
        offer_html = new Intl.NumberFormat('es-MX', {
          style: 'currency',
          currency: 'MXN',
        }).format(price)
        break
      case 'es':
        offer_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(price)
        break
      case 'uk':
        offer_html = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }).format(price)
        break
      case 'do': //dominican republic
        offer_html = new Intl.NumberFormat('es-DO', {
          style: 'currency',
          currency: 'DOP',
        }).format(price)
        break
      default:
        offer_html = new Intl.NumberFormat('es-ES', {
          style: 'currency',
          currency: 'EUR',
        }).format(price)
    }
  else offer_html = ``

  return offer_html
}
