import {
  AUTH_REQUEST,
  RESET_REQUEST,
  RESET_PASSWORD,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from '../actions/auth'
import axios from 'axios'
import config from './config'
import store from '..'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
}

const getters = {
  isAuthenticated: (state) => () => {
    return (
      !!localStorage['user-token'] &&
      !!localStorage['refresh_token'] &&
      !!localStorage['user'] &&
      !!localStorage['account_id']
    )
  },
  authStatus: (state) => state.status,
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
  },
}

const actions = {
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT)
      localStorage.clear()
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise(async (resolve, reject) => {
      commit(AUTH_REQUEST)
      const res = await axios.post('/api/user/login', user)
      //get token from api
      try {
        if (res) {
          const token = res.data.access_token
          const refresh_token = res.data.refresh_token
          localStorage.setItem('user-token', token)
          localStorage.setItem('refresh_token', refresh_token)
          localStorage.setItem('account_id', res.data.account_id)
          localStorage.setItem('is_similarity_account', res.data.is_similarity_account)
          localStorage.setItem('customer_id', res.data.customer_id)
          localStorage.setItem('user', res.data.username)
          localStorage.setItem('fullname', res.data.fullname)
          localStorage.setItem('email', res.data.email)
          localStorage.setItem('user_id', res.data.user_id)
          localStorage.setItem('super_admin', res.data.super_admin)
          localStorage.setItem('website_alias', res.data.website_alias)
          localStorage.setItem('website_url', res.data.website_url)
          localStorage.setItem('website_color', res.data.website_color)

          const hid_add_products_message = localStorage.getItem('show_PRODUCTS_MESSAGE')

          localStorage.setItem('show_PRODUCTS_MESSAGE', 'show')

          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          axios.defaults.headers.common['account'] = res.data.account_id
          axios.defaults.headers.common['customer'] = res.data.customer_id

          //get user config, let's change this later to to get all config from /login
          await axios
            .get('/api/websites/list', {
              params: {
                account: res.data.account_id,
              },
            })
            .then((res) => {
              config(res)
              commit(AUTH_SUCCESS, token)
            })
            .catch((err) => {
              reject(err)
            })
        }
        resolve(res)
      } catch (error) {
        console.log('eerrr = ', error)
        reject(error)
      }
    }).catch((err) => {
      commit(AUTH_ERROR, err)
      localStorage.clear()
    })
  },
  [RESET_REQUEST]: async ({ commit, dispatch }, email) => {
    const res = await axios.post(`/api/user/forgotPassword/${email.resetEmail}`)

    return res
  },
  [RESET_PASSWORD]: async ({ commit, dispatch }, resetPasswordData) => {
    const res = await axios.get(
      `/api/user/resetPassword/${resetPasswordData.token}/${resetPasswordData.newPassword}/${resetPasswordData.verifyPassword}`,
    )

    return res
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
